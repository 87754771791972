
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

.shadowStyle {
    box-shadow: 0px 4px 25px -1px rgba(0, 0, 0, 0.1);
    border-radius: 30px;
    background-color: #fff;
}
