
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

.wrapper {
    position: relative;

    h2 {
        font-size: 24px;
        line-height: 30px;
        font-weight: 800;

        color: #204b6e;
        @include lg {
            font-size: 35px;
            line-height: 41px;
        }
    }
    span {
        width: 161px;
        height: 8px;
        background-color: #f6cdb6;
        display: block;
        margin-top: 10px;
    }

    p {
        font-size: 16px;
        line-height: 27px;
        color: #4c5357;
        margin-top: 20px;

        @include lg {
            font-size: 18px;
            line-height: 32px;
        }
    }
}
