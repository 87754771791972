
            @import 'src/styles/mixins.scss';
        
.mainSection {
    margin-top: 80px;

    @include lg {
        margin-top: 120px;
    }

    position: relative;
    &::before {
        content: "";
        left: 50%;
        width: 100vw;
        transform: translateX(-50%);
        height: 100%;
        position: absolute;
        z-index: -1;

        background-color: #f2f7fc;
    }
    > div {
        h2 {
            font-size: 24px;
            font-weight: 800;
            margin-bottom: 30px;
            @include lg {
                font-size: 35px;
                line-height: 42px;
            }
        }
        p {
            margin-top: 10px;
            font-weight: 300;
            color: #4c5357;
            font-size: 16px;
            text-align: justify;
            @include lg {
                text-align: left;
                font-size: 20px;
                line-height: 36px;
            }
        }
    }
}
